import React, { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { navigate } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import Layout from '/src/components/layout'
import { userState } from '/src/hooks/useUser'
import { cartState, cartCountState, getCart } from '/src/hooks/useCart'
import { getRequest, postRequest } from '/src/httpClient'

const UserOneTimeLoginPage = ({ params }) => {
  const { uid, timestamp, hash } = params
  const setUserState = useSetRecoilState(userState)
  const setCartState = useSetRecoilState(cartState)
  const setCartCount = useSetRecoilState(cartCountState)

  useEffect(() => {
    postRequest('/api/user/validate', {
      uid: uid,
      timestamp: timestamp,
      hash: hash
    }).then((res) => {
      if (res) {
        getRequest('/api/user', new AbortController()).then(userRes => {
          setUserState(userRes)

          getCart().then(cartRes => {
            if (!cartRes) {
              navigate('/user')
              return
            }

            const { order_items } = cartRes.relationships
            setCartState(cartRes)

            let cartQuantity = 0
            if (order_items.data.length !== 0) {
              cartQuantity = order_items.data.map(({ attributes }) => {
                return attributes.quantity
              }).reduce(
                (a, c) => a + parseInt(c),
                0
              )
            }
            setCartCount(cartQuantity)
            navigate('/user')
          })
        })
      }
    })
  }, [])

  return (
    <Layout>
      <Row className="mt-5">
        <Col xs={12}>
          <div className="title-container">
            <h1 className="title">Password Reset Request</h1>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12}>
          <p>Please wait while we validate the request.</p>
        </Col>
      </Row>
    </Layout>
  )
}

export default UserOneTimeLoginPage

export const Head = () => <title>Reset Password</title>
